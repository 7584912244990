body {
  font-family: $brand-font;
  font-size: 16px;
  font-weight: 400;
  // display: flex;
  // min-height: 100vh;
  // flex-direction: column;
  padding-right: 0!important;
}

.wrap {
  // flex: 1;
}

img {
  max-width: 100%;
  height: auto;
}

p, li {
  font-family: $brand-font;
  font-size: 16px;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $brand-font;
  font-weight: 500;
}

h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

h3 {
  font-size: 24px;
  margin-bottom: 30px;
}


.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
    margin-bottom: 30px;
}

.video-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

#videoModal {
  padding-right: 0!important;
  .modal-dialog {
    background-color: #000;
    margin: 30vh auto 0;
    width: 95%;
    max-width: 600px;
    .modal-content {
      background-color: #000;
      border: none;
      border-radius: 0;
    }

  }
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
    border-radius: 4px;
}

.video-container iframe {
    position: absolute;
    border: none;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

.dentist-img {
  @include aspect-ratio(1,1)
  max-width: 360px;
  margin: 0 auto;
  border: 5px solid $blue;
  border-radius: 50%;
}

.dentist-img-wrap {
  .dentist-img {
    margin-bottom: 20px;
  }
  @include tablet {
    float: left;
    .dentist-img {
      width: 200px;
      margin-bottom: 10px;
      margin-right: 20px;
    }

  }

}

.dentist-1 {
  .dentist-img {
    background: url(../images/dr-marcus.jpg) no-repeat center;
    background-size: cover;
    transition: background-image 0.4s ease-in-out;

    &:hover, &:focus {
      background: url(../images/scuba-marcus.jpg) no-repeat center;
      background-size: cover;
    }
    &:after { // Preload
      content: url(../images/scuba-marcus.jpg);
      visibility: hidden;
      position: absolute;
      left: -999em;
    }
  }
}
.dentist-2 {
  .dentist-img {
    background: url(../images/dr-porter.jpg) no-repeat center;
    background-size: cover;
    transition: background-image 0.4s ease-in-out;

    &:hover, &:focus {
      background: url(../images/scuba-porter.jpg) no-repeat center;
      background-size: cover;
    }
    &:after { // Preload
      content:  url(../images/scuba-porter.jpg);
      visibility: hidden;
      position: absolute;
      left: -999em;
    }
  }
}
.dentist-3 {
  .dentist-img {
    background: url(../images/dr-amy.jpg) no-repeat center;
    background-size: cover;
    transition: background-image 0.4s ease-in-out;

    &:hover, &:focus {
      background: url(../images/scuba-amy.jpg) no-repeat center;
      background-size: cover;
    }
    &:after { // Preload
      content:  url(../images/scuba-amy.jpg);
      visibility: hidden;
      position: absolute;
      left: -999em;
    }
  }
}


.table-responsive {
  color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  margin:0;
  padding:0;
  border-collapse: collapse;
  border-spacing: 0;

  thead tr {
    background-color: $orange;
  }

  tr {
    background-color: $orange;

      border: 1px solid #ddd;
      padding: 5px;
  }

  th {
      padding: 10px;
  }

  td {
    padding: 8px;
  }

  p {
    margin-bottom: 0;
  }

  th {
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 1px;
  }
}



@media screen and (max-width: 600px) {
  .table-responsive {
    border: 0;

    thead {
        display: none;
    }

    tr {
        margin-bottom: 10px;
        display: block;
    }

    tr:after {
        content: "";
        display: table;
        clear: both;
    }

    tr:before {
        display: block;
        border-bottom: 2px solid #ddd;
    }

    td {
        box-sizing: border-box;
        display: block;
        float: left;
        clear: left;
        width: 100%;
        text-align: right;
        font-size: 13px;
        border-bottom: 1px dotted #ccc;
    }

    td:last-child {
        border-bottom: 0;
    }

    td:before {
        content: attr(data-label);
        float: left;
        text-transform: uppercase;
        font-weight: bold;
    }
  }
}

.test-size {
  display: block;
  @include mobile {
    display: none;
  }
  @include laptop {
    display: block;
  }
  @include desktop {
    display: none;
  }
}

.inner-error404 {
    padding-top: 40px;
    padding-bottom: 40px;
}
