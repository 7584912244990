.hero.interior-hero {
  background-position: right;
  position: relative;
  @include tablet {
    background-position: center;
  }
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    @include laptop {
      background-color: rgba(0,0,0,0.2);
    }
  }
  h1 {
    font-size: 36px;
    margin-bottom: 20px;
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
    color: #fff;
  }
}

.page-content {
  padding: 40px 0 60px;
  background: #E1F7FF;
  background-image: url(../images/ecailles.png);
  h1 {
    margin-bottom: 30px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    margin-bottom: 20px;
  }
  li, p {
    line-height: 24px;
  }
  .page-panel {
    margin-bottom: 30px;
  }
}

.tour-panel {
  padding: 80px 0;
  background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url(../images/office-tour.jpg) no-repeat center;
  background-size: cover;
  text-align: center;
  h2, p {
    color: #fff;
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
  }
  h2 {
    margin-top: 10px;
  }
  p {
    max-width: 500px;
    margin: 0 auto 30px;
  }
  .video-container {
    // margin-bottom: 0;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
  }
}

.fun-and-games {
  .flip-container {
    overflow: hidden;
    perspective: 1000px;
    &.block-1 {
      background-color: $green;
    }
    &.block-2 {
      background-color: $blue;
    }
    &.block-3 {
      background-color: $orange;
    }
    &:hover, &:focus {
      .flip {
        transform: rotateX(-180deg);
      }
    }
  }
  .flip {
    transition: 0.6s;
    transform-origin: 100% calc(360px / 2); /* half of height */

  	transform-style: preserve-3d;
    // @include aspect-ratio(6,4);
    height: 360px;

    .front, .back {
    	backface-visibility: hidden;
    	position: absolute;
    	top: 0;
      bottom: 0;
      right: 0;
    	left: 0;
      width: 100%;
    }
    .front {
    	z-index: 2;
    	transform: rotateX(0deg);
      .content {
        max-width: 360px;
        margin: 50px auto 0;
        padding: 0 15px;
        text-align: center;
        h2, p, .fa {
          color: #fff;
        }
        .fa {
          font-size: 6em;
        }
      }
    }

    .back {
    	transform: rotateX(180deg);
    }
  }

  .block-1-panel {
    .front {
      background-color: $green;
    }
    .back {
      background-image: url(../images/fun-1.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .block-2-panel {
    .front {
      background-color: $blue;
    }
    .back {
      background-image: url(../images/games-1.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .block-3-panel {
    .front {
      background-color: $orange;
    }
    .back {
      background-image: url(../images/fun-2.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
}

.pro-panel {
  padding: 80px 0;
  background: #E1F7FF;
  background-image: url(../images/ecailles.png);
  text-align: center;
  h2, p {
    // color: #fff;
    // text-shadow: 0 2px 4px rgba(0,0,0,0.5);
  }
  p {
    max-width: 500px;
    margin: 0 auto 30px;
  }
  img {
    display: block;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    margin-bottom: 30px;
    @include laptop {
      margin-bottom: 0;
    }
  }
}


.inner-image-full {
  width: 100%;
}

.inner-image-left {
  width: 100%;
  float: none;
  padding: 0;
  @include phablet {
    width: 45%;
    float: left;
    padding: 10px 20px 10px 0;
  }
}

.inner-image-right {
  width: 100%;
  float: none;
  padding: 0;
  @include phablet {
    width: 45%;
    float: right;
    padding: 15px 0 15px 20px;
  }
}
