.page-content.reviews-content {
  text-align: center;
  background-color: #fff;
  h3 {
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 0;
  }
  .fa {
    font-size: 80px;
  }

  .review-option {
    transition: all 0.25s ease-in-out;
    margin: 40px auto;
    max-width: 360px;
    &:hover, &:focus {
      cursor: pointer;
      transform: scale(1.1);
      box-shadow: 0 1px 1px rgba(0,0,0,.05);
    }
  }

}


.review-btn {
  display: block;
  padding: 20px 10px;
  color: #fff;
  transition: all 0.3s;
  text-align: center;

  .fa {
    font-size: 24px;
    position: relative;
    top: 1px;
    left: -5px;
  }

  &:hover, &:focus {
    color: #fff;
    text-decoration: none;
  }

  &.btn-facebook {
    background-color: #3b5998;
    &:hover, &:focus {
      background-color: darken(#3b5998,15%);
    }
  }
  &.btn-gplus {
    background-color: #dc4e41;
    &:hover, &:focus {
      background-color: darken(#dc4e41,15%);
    }
  }
}

#leaveReview {

  .modal-dialog {
    margin: 30vh auto;
  }
}

#yourExperience {
  .modal-content {
    background-color: $blue;
    .gform_wrapper .gform_heading h3.gform_title {
      margin-top: 0;
    }
  }
}
