.gform_widget {
  margin-top: 0;
  background: rgba($blue, 0.3);
  color: #262626;
  padding: 15px;
  h3 {
    margin-top: 0;
  }
  .gform_wrapper {
    margin: 0!important;
    .gform_footer {
      padding-bottom: 0;
    }
  }
}

.widget:not(.gform_widget) {
  margin-bottom: 30px;
  h3 {
    padding-bottom: 10px;
    font-weight: 300;
    margin-bottom: 15px;
    border-bottom: 1px solid #000;
  }
  ul {
    padding-left: 0;
    list-style: none;
    li {
      padding: 5px 0 5px 5px;
      a {
        font-weight: 300;
      }
      &:before {
        font-family: FontAwesome;
        content: '\f040';
        margin-right: 10px;
        color: $brand-primary;
      }
    }
  }
  &.widget_categories {
    ul li:before {
      content: '\f02c';
    }
  }

}
