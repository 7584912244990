.gform_wrapper {
  margin-top: 0;
  max-width: 100%!important;
  .gform_heading {
    h3.gform_title {
      font-size: 24px;
      margin-top: 20px;
      font-weight: 500;
    }
  }
  .gform_fields {
    .gfield {
      position: relative;
      margin-bottom: 20px;
      .gfield_label {
        position: absolute;
        margin: 0;
        top: 0;
        bottom: 0;
        left: 0;
        width: 50px;
        background-color: transparent;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        padding: 6px;
        text-indent: -9999px;
        &:before {
          color: $brand-primary;

          position: absolute;
          left: 5px;
          right: 5px;
          text-align: center;
          top: 3px;
          font-size: 25px;
          z-index: 100;
          text-indent: 0;
          font-family: FontAwesome;
        }
      }
      &#field_1_1, &#field_3_1 {
        .gfield_label:before {
          content: '\f007';
        }
      }
      &#field_1_5, &#field_3_5 {
        .gfield_label:before {
          content: '\f0e0';
          color: $yellow;
        }
      }
      &#field_1_6, &#field_3_6 {
        .gfield_label:before {
          top: 4px;
          content: '\f095';
          color: $green;
        }
      }
      &#field_1_4, &#field_3_4 {

        height: 200px;
        .gfield_label:before {
          content: '\f075';
          color: $orange;
        }
        textarea {
          height: 200px;
        }
      }
      &#field_1_7 {
        .gfield_label {
          &:before {
            display: none;
          }
        }

      }
      input, textarea {
        width: 100%!important;
        border: none;
        box-shadow: none;
        background-color: #fff;
        color: #000;
        border-radius: 0;
        padding-left: 45px!important;
        padding-top: 7px!important;
        padding-bottom: 7px!important;
        &:focus {
          outline: 0;
        }
      }
    }
  }

  .ginput_container_consent {
    position: relative;
    z-index: 999999;
    width: auto !important;
  }

  #input_1_9_1,
  #input_1_10_1 {
    width: auto !important;
  }

  .gform_footer {
    padding-top: 0!important;
    input[type="submit"] {
      @extend .btn;
      @extend .btn-orange;
      padding: 7px 40px;
    }
  }
}
