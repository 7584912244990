.btn {
  max-width: 263px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  border-radius: 0;
  font-size: 16px;
  color: #fff;
  padding: 14px 20px;
  transition: all 0.4s ease-in-out;
  &:hover, &:focus {
    color: #fff;
    transform: scale(1.1);
  }

  &.btn-orange {
    background-color: $orange;
    &:hover, &:focus {
      background-color: darken($orange, 15%);
    }
  }
}
