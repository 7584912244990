.hero {
  background: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(../images/bcd-hero.jpg) no-repeat 66%;
  background-size: cover;
  padding: 8vh 0;
  transition: padding 0.5s;
  color: #fff;
  @include tablet {
    padding: 10vh 0;
    background: url(../images/bcd-hero.jpg) no-repeat center;
    background-size: auto;
    color: #000;
  }
  @include laptop {
    background: url(../images/bcd-hero.jpg) no-repeat center;
    background-size: cover;
    padding: calc(15vh + 2vw) 0;
  }
  h1 {
    color: #fff;
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 30px;
    @include mobile {
      font-size: 30px;
    }
    @include laptop {
      font-size: 32px;
    }
  }
  p {
    color: #fff;
    line-height: 24px;
    margin-bottom: 30px;
  }

  .btn.btn-block {
    max-width: 100%;
    &.contact-btn {
      margin-bottom: 15px;
      @include tablet {
        margin-bottom: 0;
      }
    }
  }
}





#why-us {

  text-align: center;
  margin-top: -1px;
  @include tablet {
    padding: 60px 0;
    background: url(../images/mobile-strapline.svg) no-repeat center;
    background-size: cover;
  }
  @include laptop {
    background: url(../images/full-strapline.svg) no-repeat center;
    background-size: cover;
    padding: 235px 0 60px;
    margin-top: -245px;
  }
  @include desktop {
    padding: 225px 0 60px;
    margin-top: -225px;
  }

  .strapline {
    padding: 40px 0;
    background-color: $dark-green;
    @include tablet {
      padding: 0 0 60px;
      background-color: transparent;
    }
    @include laptop {
      padding: 40px 0 60px;
    }
    p {
      color: #fff;
      font-size: 20px;
      @include laptop {
        font-size: 24px;
      }
    }
  }

  .choose-us {
    padding: 40px 0 60px;
    background-color: #bbefd0;
    @include tablet {
      padding: 0;
      background-color: transparent;
    }
  }

  .why-text {
    margin-bottom: 60px;
    font-size: 18px;
    @include laptop {
      font-size: 21px;
    }
  }

  .content {
    h3 {
      margin: 15px 0;
      font-size: 22px;
      @include laptop {
        margin: 30px 0;
        font-size: 24px;
      }
    }
    p {
      max-width: 360px;
      margin: 0 auto 40px;
    }
  }

  .feat-img {
    position: relative;
    img {
      height: 200px;
      z-index: 99;
    }
    .font-icons {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 40px;
      z-index: 100;
    }
    .fa {
      color: #fff;
      font-size: 57px;
      &.fa-child {
        font-size: 48px;
      }
    }
  }

}


#feat {
  background-image: url(../images/feat.svg);
  background-repeat: no-repeat;
  background-position: right;
  margin-top: -1px;
  background-size: cover;
  text-align: center;
  @include tablet {
    background-position: center;
  }
  .dentists {
    color: #fff;
    padding: 60px 0;
    @include laptop {
      padding: 30px 0 60px;
    }

    .dentist-img {

      margin-bottom: 50px;
    }

    h3 {
      margin-top: 0;
    }

    p {
      margin: 0 auto 20px;
      max-width: 360px;
      @include laptop {
        margin: 0 auto 40px;
      }
    }

    .read-more {
      color: #fff;
      border-bottom: 2px solid #fff;
      padding: 2px 0;
      display: inline-block;
      position: relative;
      overflow: hidden;

      &:before {
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        transition: all 0.3s ease-out;
        outline: 1px solid transparent;
        transform: translateX(-50%);
        height: 2px;
        background-color: #fff;
        visibility: hidden;
        content: '';
      }
      &:hover, &:focus {
        text-decoration: none;
        &:before {
          width: 100%;
          visibility: visible;
        }
      }
    }

    .dentist-1, .dentist-2, .dentist-3 {
      margin-bottom: 40px;
    }
    // .dentist-1 {
    //   .dentist-img {
    //     background: url(../images/marcus.jpg) no-repeat center;
    //     background-size: cover;
    //     transition: background-image 0.4s ease-in-out;
    //
    //     &:hover, &:focus {
    //       background: url(../images/mister-marcus.jpg) no-repeat center;
    //       background-size: cover;
    //     }
    //     &:after { // Preload
    //       content: url(../images/mister-marcus.jpg);
    //       visibility: hidden;
    //       position: absolute;
    //       left: -999em;
    //     }
    //   }
    // }
    // .dentist-2 {
    //   .dentist-img {
    //     background: url(../images/steve.jpg) no-repeat center;
    //     background-size: cover;
    //     transition: background-image 0.4s ease-in-out;
    //
    //     &:hover, &:focus {
    //       background: url(../images/scuba-steve.jpg) no-repeat center;
    //       background-size: cover;
    //     }
    //     &:after { // Preload
    //       content:  url(../images/scuba-steve.jpg);
    //       visibility: hidden;
    //       position: absolute;
    //       left: -999em;
    //     }
    //   }
    // }

  }


  .blog-feat {
    padding: 60px 0;
    @include tablet {
      padding: 60px 0 80px;
    }
    @include laptop {
      padding: 60px 0 90px;
    }
    h2 {
      margin: 0 auto 50px;
      max-width: 400px;
      @include tablet {
        max-width: 100%;
      }
    }

    .feat-blog-item {
      max-width: 360px;
      margin: 0 auto 40px;
      color: #fff;
      .blog-img {
        @include aspect-ratio(36,24);
      }
      .header-bar {
        background-color: #495052;
        h3 {
          margin: 0;
          padding: 20px 0;

        }
      }
      .content {
        padding: 15px;
        p {
          margin-bottom: 20px;
          @include tablet {
            min-height: 200px;
          }
          @include laptop {
            min-height: 250px;
          }
          @include desktop {
            min-height: 185px;
          }
          a {
            display: none;
          }
        }
        a {
          color: #fff;
        }
      }

      &.blog-1 {
        // .blog-img {
        //   background: url(../images/simplifying-orthodontics.jpg) no-repeat center;
        //   background-size: cover;
        // }
        .content {
          background-color: $green;
        }
      }

      &.blog-2 {
        // .blog-img {
        //   background: url(../images/flouride.jpg) no-repeat center;
        //   background-size: cover;
        // }
        .content {
          background-color: $blue;
        }
      }

      &.blog-3 {
        display: none;

        @include laptop {
          display: block;
        }
        // .blog-img {
        //   background: url(../images/baby-teeth.jpg) no-repeat center;
        //   background-size: cover;
        // }
        .content {
          background-color: $yellow;
        }
      }
    }

  }

}

#kid-friendly {
  background: url(../images/pro-bkg.jpg) no-repeat fixed center;
  background-size: cover;
  text-align: center;
  padding: 90px 0;
  @include laptop {
    padding: 170px 0;
  }
  .content-box {
    background-color: rgba(255,255,255,0.8);
    padding: 30px 15px;
    margin: 0 -15px;
    h2 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 32px;
    }
    p {
      margin: 20px 0 30px;
    }
  }
}

#logos {
  padding: 60px 0;
  text-align: center;
  img {
    margin-bottom: 15px;
    @include tablet {
      max-width: 156px;
      margin-bottom: 0;
    }
  }
}
