article.post {
    background-color: #fff;
    padding: 0 15px 15px;
    margin-bottom: 30px;
    color: #262626;
    text-align: center;
    background: rgba($blue, 0.3);
    @include tablet {
      text-align: left;
    }


    .header-bar {
      h3 {
        padding-top: 15px;
        margin: 0;
        @include tablet {
          padding: 15px 0;
          float: left;
        }
      }
      .publish-date {
        padding-bottom: 15px;
        margin-bottom: 0;
        @include tablet {
          padding: 15px 0;
          float: right;
        }
      }
    }

    .blog-img {
      @include aspect-ratio(4,3);
      max-width: 600px;
      margin: 0 auto 15px;
      @include tablet {
        margin-bottom: 0;
      }
    }

    a {
      color: #262626;
    }

    .entry-summary {
      a:last-of-type {
        display: block;
        margin-top: 10px;
      }
    }
}

.single {
  article.post {
    padding: 20px;
    text-align: left;
    h1 {
      margin: 0;
    }
    .publish-date {
      margin-bottom: 20px;
    }
    img {
      margin: 0 auto 20px;
      display: block;
    }
  }
}


nav.pagination {
  display: block;
  .nav-links {
    text-align: center;
    .page-numbers {
      display: inline-block;
      &.prev {
        float: left;
      }
      &.next {
        float: right;
      }
    }
  }
}
