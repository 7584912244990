header.banner {
  margin-top: -1px;
  background: url(../images/header.png) no-repeat center;
  background-size: cover;

  .navbar-logo {
    display: block;
    max-width: 225px;
    img {
      float: left;
      margin: 10px 0 17px;

    }
    @include laptop {
      margin: 18px auto;
      img {
        float: none;
        margin: 0;
      }
    }
    @include desktop {
      margin: 19px auto;

    }
  }

  .nav {
    li {
      a {
        text-transform: uppercase;

        &:hover,
        &:focus {
          background-color: transparent;
        }

        &[target="_blank"]::after {
          content: '\f08e';
          margin-left: 5px;
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
        }
      }
    }
  }

  @include laptop {
    .logo-nav {
      width: 24%;
      position: relative;
      float: left;
      left: 38%;
    }

    .left-nav {
      position: relative;
      float: left;
      width: 38%;
      left: -24%;
    }

    .right-nav {
      position: relative;
      float: left;
      width: 38%;
    }
  }

  .top {
    @include laptop {
      margin: 35px 2px 5px;
    }
    @include desktop {
      margin: 40px 5px 12px;
    }
    .nav {
      li {
        a {
          font-size: 12px;
          padding: 10px 7px;
          @include desktop {
            font-size: 14px;
            padding: 10px;
          }
        }
      }
    }
  }


  .main {
    .nav {
      li {
        a {
          font-size: 14px;
          padding: 10px 9px;
          @include desktop {
            font-size: 16px;
            padding: 10px 15px;
          }

        }
      }
    }
  }

  .sideNav {
    .nav {
      li {
        a {
          padding: 8px 15px;
          text-transform: none;
        }
      }
    }
  }
}
