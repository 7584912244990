footer {
  background: url(../images/footer.svg) no-repeat center;
  background-size: cover;

  #testimonials {
    padding: 130px 0;
    max-width: 360px;
    margin: 0 auto;
    @include tablet {
      max-width: 100%;
    }
    @include laptop {
      padding: 60px 0;
    }
    .item {

      .quote-img {
        @include aspect-ratio(1,1);
        border-radius: 50%;
        max-width: 275px;
        margin: 0 auto;
      }

      .caption {
        margin-top: 20px;
        min-height: 270px;
        @include tablet {
          margin-top: 0;
          min-height: auto;
        }
        @include laptop {
          margin-top: 60px;
        }
        @include desktop {
          margin-top: 100px;
        }
        .quote {
          margin-bottom: 15px;
          @include mobile {
            font-size: 18px;
          }
        }
        .author {
          font-size: 18px;
          text-transform: uppercase;
        }
      }

    }

    .carousel-control {
      position: static;
      background: none;
      width: auto;
      top: auto;
      right: 15px;
      bottom: 0;
      @include tablet {
        position: absolute;
      }
      .fa {
        font-size: 36px;
        color: #495052;
      }
      &.left {
        right: 55px;
        left: auto;
      }
    }
  }

  #footer {
    color: #fff;
    font-size: 14px;
    padding: 40px 0;
    @include tablet {
      font-size: 15px;
    }
    @include laptop {
      padding: 80px 0;
    }
    h3 {
      border-bottom: 1px solid #fff;
      font-size: 18px;
      text-transform: uppercase;
      padding-bottom: 10px;
      margin-bottom: 15px;
    }

    .address {
      line-height: 26px;
    }

    .opening-hours {
      width: 100%;
      line-height: 26px;
      td {
        width: 50%;
        @include laptop {
          &.day {
            width: 40%;
          }
        }
        @include desktop {
          width: 50%;
        }
      }
    }

    .services-nav {
      .nav {
        li {
          width: 50%;
          float: left;
          a {
            color: #fff;
            font-size: 14px;
            padding: 0;
            line-height: 26px;
            @include tablet {
              font-size: 15px;
            }
            &:hover, &:focus {
              background-color: transparent;
            }
          }
        }
      }
    }

    .office-nav {
      li {
        a {
          color: #fff;
          font-size: 14px;
          padding: 0;
          line-height: 26px;

          @include tablet {
            font-size: 15px;
          }

          &:hover,
          &:focus {
            background-color: transparent;
          }

          &[target="_blank"]::after {
            content: '\f08e';
            margin-left: 5px;
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
          }
        }
      }
    }

    .social-media {
      margin-top: 20px;
      .fa {
        font-size: 24px;
        transition: all 0.3s;
        margin-right: 5px;
        color: #fff;
        &:hover, &:focus {
          color: #000;
        }
      }
    }

  }
}

#copyright {
  margin-top: -1px;
  text-align: center;
  background-color: $dark-green;
  color: #fff;
  font-size: 12px;
  padding: 10px 0;

  .linksCopy {
    @include laptop {
      float: left;
    }
    a {
      color: #fff;
    }
  }

  .clientCopy {
    @include laptop {
      float: right;
    }
  }
}
